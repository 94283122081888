import { ParentState } from "@capone/checkout";
import { State } from "xstate";
import { PackagesMachineContext } from "../types";
import { Person } from "@b2bportal/air-booking-api";

type LodgingPassengerStateType = State<PackagesMachineContext>;
type LodgingPassengerStateWithoutValue = Pick<
  LodgingPassengerStateType,
  "context"
>;

export const getSelectedPassengerIdParent = (
  state: LodgingPassengerStateWithoutValue
) => state.context[ParentState.lodgingPassengerInformation].selectedPassengerId;

export const getPassengerError = (state: LodgingPassengerStateWithoutValue) =>
  state.context[ParentState.lodgingPassengerInformation].error;

export const getSelectedUserPassengerParent = (
  state: LodgingPassengerStateWithoutValue
): Person | undefined => {
  const selectedPassengerId = getSelectedPassengerIdParent(state);
  const userPassengers =
    state.context[ParentState.passengerInformation].userPassengers;

  return userPassengers?.find((person) => person.id === selectedPassengerId);
};
