import React from "react";
import { RouteComponentProps } from "react-router";
import { GenericInfoPopup, Icon, IconName } from "halifax";
import { BookingErrorModalConnectorProps } from "./container";
import "./styles.scss";
import {
  CartQuoteEventType,
  cartQuoteSelectors,
  getParentState,
  ParentState,
  useCheckoutState,
  useCheckoutStateSelector,
} from "@capone/checkout";
import { TEvent } from "../../state/events";
import { PackagesMachineContext } from "../../state/types";
import { getErrorModalProps } from "./utils";
import { PurchaseError } from "@b2bportal/purchase-api";
import { PATH_HOME } from "../../../../utils/paths";
import { trackEvent } from "../../../../api/v0/trackEvent";
import {
  MODAL_ALERT,
  ModalAlertProperties,
  ModalCategoryType,
  ModalScreens,
} from "redmond";

export interface IBookingErrorModalProps
  extends BookingErrorModalConnectorProps,
    RouteComponentProps {}

export const BookingErrorModal = ({ history }: IBookingErrorModalProps) => {
  const cartErrorModal = useCheckoutStateSelector(
    cartQuoteSelectors.getCartQuoteError
  );
  const open = useCheckoutStateSelector(
    cartQuoteSelectors.getOpenCartQuoteErrorModal
  );

  const [state, send] = useCheckoutState<TEvent, PackagesMachineContext>();

  const parentState = getParentState(state.value) as ParentState;

  const clearError = () => send(CartQuoteEventType.CLEAR_CART_QUOTE_ERROR);

  const updateTravelers = () => {
    send(CartQuoteEventType.OPEN_PASSENGER_PICKER);
    clearError();
  };

  const searchAgain = () => {
    history.push(PATH_HOME);
  };

  const errorModalProps = getErrorModalProps(
    cartErrorModal?.type,
    cartErrorModal?.data as PurchaseError[],
    updateTravelers,
    searchAgain
  );

  const modalEventProperties: ModalAlertProperties = {
    type: cartErrorModal?.type || "",
    primary_button: errorModalProps.buttons?.[0]?.buttonText || "",
    secondary_button: errorModalProps.buttons?.[1]?.buttonText || "",
    screen: ModalScreens.PACKAGES_CHECKOUT,
    category: ModalCategoryType.TROUBLE,
    modal_subtitle: errorModalProps.subtitle as string,
    modal_title: errorModalProps.title as string,
    agent_subtitle: errorModalProps.subtitle as string,
    agent_title: errorModalProps.title as string,
    funnel: "packages",
    step: parentState,
  };

  React.useEffect(() => {
    if (open)
      trackEvent({
        eventName: MODAL_ALERT,
        properties: {
          ...modalEventProperties,
        },
      });
  }, [open]);

  return (
    <GenericInfoPopup
      open={open}
      image={<Icon className="error-icon" name={IconName.ErrorState} />}
      {...errorModalProps}
    />
  );
};
