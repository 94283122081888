import { FlightError } from "@b2bportal/air-booking-api";
import {
  PaymentError,
  Product,
  ProductError,
  PurchaseError,
  PurchaseErrorEnum,
} from "@b2bportal/purchase-api";
import {
  EXPIRED_PASSPORT_SUBTITLE,
  GENERIC_ERROR_SUBTITLE,
  GENERIC_ERROR_TITLE,
  INFANT_KTN_SUBTITLE,
  INFANT_REDRESS_SUBTITLE,
  INVALID_PASSENGERS_SUBTITLE,
  INVALID_PASSENGERS_TITLE,
  INVALID_PASSPORT_NUMBER_SUBTITLE,
  LAP_INFANT_TOO_OLD_SUBTITLE,
  LAP_INFANTS_UNSUPPORTED_SUBTITLE,
  MALFORMED_KTN_SUBTITLE,
  MALFORMED_REDRESS_SUBTITLE,
  MIN_AGE_NOT_MET_SUBTITLE,
  MISSING_AIRLINE_LOCATOR_SUBTITLE,
  MISSING_CONTACT_INFO_TITLE,
  MISSING_NATIONALITY_SUBTITLE,
  MISSING_PASSPORT_SUBTITLE,
  NO_AVAILABILITY_SUBTITLE,
  NO_AVAILABILITY_TITLE,
  NO_CONTACT_INFO_SUBTITLE,
  PASSENGER_VALIDATION_TITLE,
  SEATED_INFANTS_UNSUPPORTED_SUBTITLE,
  TECHNICAL_ERROR_SUBTITLE,
  TOO_MANY_LAP_INFANTS_SUBTITLE,
  TOO_MANY_PASSENGERS_SUBTITLE,
  TOO_MANY_PASSENGERS_TITLE,
  UNABLE_TO_COMPLETE_BOOKING_TITLE,
  UPDATE_TRAVELERS_CTA_TEXT,
  UPGRADE_REQUIRED_TITLE,
  SEARCH_AGAIN_CTA_TEXT,
} from "./textConstants";
import { IGenericInfoPopupProps } from "halifax/build/GenericInfoPopup";
import { CartQuoteErrorModal } from "@capone/checkout";

const UPDATE_TRAVELERS_BUTTONS = (
  onClick: () => void
): IGenericInfoPopupProps["buttons"] => [
  {
    buttonText: UPDATE_TRAVELERS_CTA_TEXT,
    onClick,
  },
];

const SEARCH_AGAIN_BUTTONS = (
  onClick: () => void
): IGenericInfoPopupProps["buttons"] => [
  {
    buttonText: SEARCH_AGAIN_CTA_TEXT,
    onClick,
  },
];

const DEFAULT_ERROR_MODEL_PROPS = (
  onClick?: () => void
): Pick<IGenericInfoPopupProps, "title" | "subtitle" | "buttons"> => ({
  title: GENERIC_ERROR_TITLE,
  subtitle: GENERIC_ERROR_SUBTITLE,
  buttons: onClick ? SEARCH_AGAIN_BUTTONS(onClick) : [],
});

const getFlightError = (
  flightError: FlightError,
  updateTravelers: () => void,
  searchAgain: () => void
): Pick<IGenericInfoPopupProps, "title" | "subtitle" | "buttons"> => {
  switch (flightError) {
    case FlightError.CheckInMinimumAgeNotMet:
      return {
        title: INVALID_PASSENGERS_TITLE,
        subtitle: MIN_AGE_NOT_MET_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTONS(updateTravelers),
      };
    case FlightError.ExpiredPassport:
      return {
        title: PASSENGER_VALIDATION_TITLE,
        subtitle: EXPIRED_PASSPORT_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTONS(updateTravelers),
      };
    case FlightError.IllegalLapInfantKTN:
      return {
        title: INVALID_PASSENGERS_TITLE,
        subtitle: INFANT_KTN_SUBTITLE, // todo: we don't have the pax name
        buttons: UPDATE_TRAVELERS_BUTTONS(updateTravelers),
      };
    case FlightError.IllegalLapInfantRedress:
      return {
        title: INVALID_PASSENGERS_TITLE,
        subtitle: INFANT_REDRESS_SUBTITLE, // todo: we don't have the pax name
        buttons: UPDATE_TRAVELERS_BUTTONS(updateTravelers),
      };
    case FlightError.InvalidCustomer:
      return {
        title: INVALID_PASSENGERS_TITLE,
        subtitle: INVALID_PASSENGERS_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTONS(updateTravelers),
      };
    case FlightError.InvalidPassengers:
      return {
        title: INVALID_PASSENGERS_TITLE,
        subtitle: INVALID_PASSENGERS_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTONS(updateTravelers),
      };
    case FlightError.InvalidPassportNumber:
      return {
        title: PASSENGER_VALIDATION_TITLE,
        subtitle: INVALID_PASSPORT_NUMBER_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTONS(updateTravelers),
      };
    case FlightError.LapInfantTooOld:
      return {
        title: INVALID_PASSENGERS_TITLE,
        subtitle: LAP_INFANT_TOO_OLD_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTONS(updateTravelers),
      };
    case FlightError.LapInfantsUnsupported:
      return {
        title: INVALID_PASSENGERS_TITLE,
        subtitle: LAP_INFANTS_UNSUPPORTED_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTONS(updateTravelers),
      };
    case FlightError.MalformedKnownTravelerNumber:
      return {
        title: PASSENGER_VALIDATION_TITLE,
        subtitle: MALFORMED_KTN_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTONS(updateTravelers),
      };
    case FlightError.MalformedRedressNumber:
      return {
        title: PASSENGER_VALIDATION_TITLE,
        subtitle: MALFORMED_REDRESS_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTONS(updateTravelers),
      };
    case FlightError.MissingAirlineLocator:
      return {
        title: UNABLE_TO_COMPLETE_BOOKING_TITLE,
        subtitle: MISSING_AIRLINE_LOCATOR_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTONS(searchAgain),
      };
    case FlightError.MissingNationality:
      return {
        title: INVALID_PASSENGERS_TITLE,
        subtitle: MISSING_NATIONALITY_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTONS(updateTravelers),
      };
    case FlightError.MissingPassport:
      return {
        title: PASSENGER_VALIDATION_TITLE,
        subtitle: MISSING_PASSPORT_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTONS(updateTravelers),
      };
    case FlightError.MissingPassportNumber:
      return {
        title: PASSENGER_VALIDATION_TITLE,
        subtitle: MISSING_PASSPORT_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTONS(updateTravelers),
      };
    case FlightError.NoAvailability:
      return {
        title: NO_AVAILABILITY_TITLE,
        subtitle: NO_AVAILABILITY_SUBTITLE,
        buttons: SEARCH_AGAIN_BUTTONS(searchAgain),
      };
    case FlightError.NoAdultPassenger:
      return {
        title: INVALID_PASSENGERS_TITLE,
        subtitle: MIN_AGE_NOT_MET_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTONS(updateTravelers),
      };
    case FlightError.NoContactInformation:
      return {
        title: MISSING_CONTACT_INFO_TITLE,
        subtitle: NO_CONTACT_INFO_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTONS(updateTravelers),
      };
    case FlightError.NoTicketlessResponse:
      return {
        title: UNABLE_TO_COMPLETE_BOOKING_TITLE,
        subtitle: TECHNICAL_ERROR_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTONS(updateTravelers),
      };
    case FlightError.ProviderError:
      return {
        title: UNABLE_TO_COMPLETE_BOOKING_TITLE,
        subtitle: TECHNICAL_ERROR_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTONS(updateTravelers),
      };
    case FlightError.SeatedInfantsUnsupported:
      return {
        title: INVALID_PASSENGERS_TITLE,
        subtitle: SEATED_INFANTS_UNSUPPORTED_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTONS(updateTravelers),
      };
    case FlightError.TooManyLapInfants:
      return {
        title: INVALID_PASSENGERS_TITLE,
        subtitle: TOO_MANY_LAP_INFANTS_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTONS(updateTravelers),
      };
    case FlightError.TooManyPassengers:
      return {
        title: TOO_MANY_PASSENGERS_TITLE,
        subtitle: TOO_MANY_PASSENGERS_SUBTITLE,
        buttons: UPDATE_TRAVELERS_BUTTONS(updateTravelers),
      };
    case FlightError.TransientServiceError:
      return {
        title: UNABLE_TO_COMPLETE_BOOKING_TITLE,
        subtitle: TECHNICAL_ERROR_SUBTITLE,
        buttons: SEARCH_AGAIN_BUTTONS(searchAgain),
      };
    case FlightError.UnknownSabreAppError:
      return {
        title: UNABLE_TO_COMPLETE_BOOKING_TITLE,
        subtitle: TECHNICAL_ERROR_SUBTITLE,
        buttons: SEARCH_AGAIN_BUTTONS(searchAgain),
      };
    case FlightError.UpgradeRequired:
      return {
        title: UPGRADE_REQUIRED_TITLE,
        subtitle: TECHNICAL_ERROR_SUBTITLE,
        buttons: SEARCH_AGAIN_BUTTONS(searchAgain),
      };
    default:
      // default cover FlightErrors that should not occur in PriceQuote
      return DEFAULT_ERROR_MODEL_PROPS(searchAgain);
  }
};

// const getAirPriceFreezeError = (pfError: PriceFreezeError) => {
//   switch (pfError.PriceFreezeError) {
//     case PriceFreezeErrorEnum.NotEnoughSeats:
//       return DEFAULT_ERROR_MODEL_PROPS;
//     case PriceFreezeErrorEnum.OfferNotFound:
//       return DEFAULT_ERROR_MODEL_PROPS;
//     case PriceFreezeErrorEnum.PriceFreezeErrorCode:
//       return DEFAULT_ERROR_MODEL_PROPS;
//     case PriceFreezeErrorEnum.ProductNotFound:
//       return DEFAULT_ERROR_MODEL_PROPS;
//     default:
//       return DEFAULT_ERROR_MODEL_PROPS;
//   }
// };

const getProductError = (
  error: ProductError,
  updateTravelers: () => void,
  searchAgain: () => void
) => {
  switch (error.value.type) {
    // case Product.AirPriceFreeze: {
    //   const pfError = error.value.value as PriceFreezeError;
    //   return getAirPriceFreezeError(pfError);
    // }
    case Product.Flight: {
      const flightError = error.value.value as FlightError;
      return getFlightError(flightError, updateTravelers, searchAgain);
    }
    case Product.Seats: {
      // https://github.com/hopper-org/b2b-interfaces/blob/8316205c08da3b4405647ff5245f3bdc76f0ef73/purchase/src/main/scala/com/hopper/b2b/purchase/Models.scala#L114
      const flightError = error.value.value as FlightError;
      return getFlightError(flightError, updateTravelers, searchAgain);
    }
    default:
      // covers:
      // - Product.Ground
      // - Product.Hotel
      return DEFAULT_ERROR_MODEL_PROPS(updateTravelers);
  }
};

const getPaymentError = (_error: PaymentError, onClick?: () => void) => {
  return DEFAULT_ERROR_MODEL_PROPS(onClick);
};

export const getErrorModalProps = (
  type?: CartQuoteErrorModal,
  data?: PurchaseError[],
  updateTravelers?: () => void,
  searchAgain?: () => void
): Pick<IGenericInfoPopupProps, "title" | "subtitle" | "buttons"> => {
  switch (type) {
    case CartQuoteErrorModal.FailedPolling: {
      const head = data?.[0]; // todo: we probably want to have some sort of priority order on errors?
      switch (head?.Error) {
        case PurchaseErrorEnum.NoAvailability:
          // todo: No Availability should only be a product error
          return {
            title: NO_AVAILABILITY_TITLE,
            subtitle: NO_AVAILABILITY_SUBTITLE,
            buttons: SEARCH_AGAIN_BUTTONS(searchAgain || (() => {})),
          };
        case PurchaseErrorEnum.PaymentError:
          return getPaymentError(head as PaymentError, searchAgain);
        case PurchaseErrorEnum.ProductError: // todo. Missing `Error` attribute
          return getProductError(
            head as ProductError,
            updateTravelers || (() => {}),
            searchAgain || (() => {})
          );
        default:
          // default case covers
          // - PurchaseErrorEnum.AuthError
          // - PurchaseErrorEnum.ExpiredFulfillToken
          // - PurchaseErrorEnum.ExpiredQuoteToken
          // - PurchaseErrorEnum.FraudAutoReject
          // - PurchaseErrorEnum.InActivity
          // - PurchaseErrorEnum.InvalidSession
          // - PurchaseErrorEnum.NoPaymentsProvided
          // - PurchaseErrorEnum.NonDistinctPayments
          // - PurchaseErrorEnum.ErrorCode (because this isn't standardized enough yet)
          return DEFAULT_ERROR_MODEL_PROPS(searchAgain);
      }
    }
    default:
      return DEFAULT_ERROR_MODEL_PROPS(searchAgain);
  }
};
