import { connect, ConnectedProps } from "react-redux";
import { SelectedPackagePricingCard } from "./component";
import { getSelectedPackageByLodgingId } from "../../../../hotel-shop/reducer";
import { IStoreState } from "../../../../../reducers/types";
import { getTravelersCount } from "../../../../availability/reducer";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../../rewards/reducer";
import { getNightCount } from "../../../../search/reducer";
import {
  getParsedPackagesByOutboundFareSlice,
  getSelectedTrip,
} from "../../../reducer";

const mapStateToProps = (state: IStoreState) => {
  const selectedTrip = getSelectedTrip(state);
  const outboundPackageSlices = getParsedPackagesByOutboundFareSlice(state);
  return {
    selectedPackageByLodging: getSelectedPackageByLodgingId(state),
    rewardsKey:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state) || undefined,
    nightCount: getNightCount(state),
    travelersCount: getTravelersCount(state),
    selectedPackageByFlight: selectedTrip?.outgoingFareSliceId
      ? outboundPackageSlices[selectedTrip.outgoingFareSliceId]
      : undefined,
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type SelectedPackagePricingCardConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedSelectedPackagePricingCard = connector(
  SelectedPackagePricingCard
);
