import React, { useContext, useRef } from "react";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import "./styles.scss";
import { DesktopPackagesBookWorkflowConnectorProps } from "./container";
import {
  B2BSpinner,
  ButtonWrap,
  CheckoutStepper,
  Icon,
  IconName,
  LoadingIndicator,
} from "halifax";
import clsx from "clsx";
import { PATH_HOME } from "../../../../utils/paths";
import { ClientContext } from "../../../../App";
import {
  CHANGE_CTA_TEXT,
  FLIGHT_TRAVELERS_HEADING,
  HEADER_MAIN_TEXT,
  HEADER_SUB_TEXT,
  HOTEL_TRAVELERS_HEADING,
  LOADING_TEXT,
  SEAT_SELECTION_HEADING,
  SELECTED_SEATS_COMPLETED_TEXT,
  SKIPPED_SEATS_COMPLETED_TEXT,
} from "./textConstants";
import { PackagesShopProgressBar, PackageDetailsPanel } from "../";
import { FlightBookPassengerSelection } from "../";
import {
  FlightPassengerSelectors,
  getParentState,
  ParentState,
  SeatSelectors,
  useCheckoutState,
  useCheckoutStateSelector,
} from "@capone/checkout";
import { Event, TEvent } from "../../state/events";
import { PackagesMachineContext } from "../../state/types";
import { PackagesContactInfoForm } from "../ContactInfoForm";
import { HotelBookPassengerSelection } from "../HotelBookPassengerSelection";
import { LodgingPassengerInformationSelectors } from "../../state/selectors";
import { BookingInProgressModal } from "../BookingInProgressModal";
import { BookingErrorModal } from "../BookingErrorModal";
import { getCheckoutSteps } from "./utils";
import { SeatSelection } from "../SeatSelection";

export interface IDesktopPackagesBookWorkflowProps
  extends RouteComponentProps,
    DesktopPackagesBookWorkflowConnectorProps {}

export const DesktopPackagesBookWorkflow = ({
  history,
}: IDesktopPackagesBookWorkflowProps) => {
  const clientContext = useContext(ClientContext);

  const flightPAXSectionRef = useRef<HTMLDivElement>(null);
  const hotelPAXSectionRef = useRef<HTMLDivElement>(null);
  const seatSelectionSectionRef = useRef<HTMLDivElement>(null);

  const [state, send] = useCheckoutState<TEvent, PackagesMachineContext>();

  const parentState = getParentState(state.value) as ParentState;

  const selectedFlightPassengers = useCheckoutStateSelector(
    FlightPassengerSelectors.getAllSelectedUserPassengersParent
  );
  const selectedHotelPassenger = useCheckoutStateSelector(
    LodgingPassengerInformationSelectors.getSelectedUserPassengerParent
  );
  const selectedSeats = useCheckoutStateSelector(
    SeatSelectors.getSelectedSeats
  );
  const seatTotalPricing = useCheckoutStateSelector(
    SeatSelectors.getSeatTotalPricing
  );
  const skippedSeatSelection = useCheckoutStateSelector(
    SeatSelectors.getSkipSeatSelection
  );

  const numPassengersWithSelectedSeats = React.useMemo(
    () => new Set(selectedSeats.map((seat) => seat.person_id)).size,
    [selectedSeats]
  );

  const goToFlightTravelers = () => {
    send(Event.GO_TO_PASSENGER_SELECT);
  };

  const goToHotelTravelers = () => {
    send(Event.GO_TO_HOTEL_PASSENGER_SELECT);
  };

  const goToSeatSelection = () => {
    send(Event.GO_TO_SEAT_SELECTION);
  };

  React.useEffect(() => {
    switch (parentState) {
      case ParentState.passengerInformation:
        flightPAXSectionRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case ParentState.lodgingPassengerInformation:
        hotelPAXSectionRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case ParentState.seatSelection:
        seatSelectionSectionRef.current?.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
  }, [parentState]);

  return (
    <Box className="desktop-packages-book-workflow-root">
      <CheckoutStepper
        steps={getCheckoutSteps(parentState)}
        headerCopy={HEADER_MAIN_TEXT}
        subHeaderCopy={HEADER_SUB_TEXT}
        logo={
          <ButtonWrap
            className="logo"
            onClick={() => {
              history.push(PATH_HOME);
            }}
          >
            {clientContext.logo}
          </ButtonWrap>
        }
        className={clsx("b2b", "combined-step")}
      />

      <Box className="packages-book-progress-bar-wrapper">
        <PackagesShopProgressBar />
      </Box>

      <Box className="packages-book-body-root">
        <Box className="packages-book-body-wrapper">
          <Box className="packages-book-body-left">
            <PackageDetailsPanel />

            <div
              className={clsx("packages-book-section", {
                selected: [
                  ParentState.passengerInformation,
                  ParentState.passport,
                  ParentState.contactInformation,
                ].includes(parentState),
              })}
              ref={flightPAXSectionRef}
            >
              <Box className="packages-book-section-main-content-wrapper">
                <Typography
                  variant="h3"
                  className="packages-book-section-heading"
                >
                  {FLIGHT_TRAVELERS_HEADING}
                </Typography>

                {parentState === ParentState.loading ? (
                  <LoadingIndicator
                    className="packages-checkout-loading-indicator"
                    indicatorSize="small"
                    indicator={B2BSpinner}
                    message={LOADING_TEXT}
                  />
                ) : [
                    ParentState.passengerInformation,
                    ParentState.passport,
                    ParentState.contactInformation,
                  ].includes(parentState) ? (
                  <>
                    <hr />

                    <FlightBookPassengerSelection />

                    <hr />

                    <PackagesContactInfoForm />
                  </>
                ) : selectedFlightPassengers.length ? (
                  <Box className="packages-book-filled-section">
                    <Icon name={IconName.User} />
                    <Typography className="packages-book-filled-section-content">
                      {selectedFlightPassengers.reduce(
                        (nameString, person, i) => {
                          const firstLastName = `${person.givenName} ${person.surname}`;
                          if (i > 0) return `${nameString}, ${firstLastName}`;

                          return firstLastName;
                        },
                        ""
                      )}
                    </Typography>
                  </Box>
                ) : null}
              </Box>
              {![
                ParentState.passengerInformation,
                ParentState.passport,
                ParentState.contactInformation,
              ].includes(parentState) &&
                !!selectedFlightPassengers.length && (
                  <Box className="packages-book-section-change-cta-wrapper">
                    <ButtonWrap onClick={goToFlightTravelers}>
                      <Typography>{CHANGE_CTA_TEXT}</Typography>
                    </ButtonWrap>
                  </Box>
                )}
            </div>

            <div
              className={clsx("packages-book-section", {
                selected: [ParentState.lodgingPassengerInformation].includes(
                  parentState
                ),
              })}
              ref={hotelPAXSectionRef}
            >
              <Box className="packages-book-section-main-content-wrapper">
                <Typography
                  variant="h3"
                  className="packages-book-section-heading"
                >
                  {HOTEL_TRAVELERS_HEADING}
                </Typography>

                {[ParentState.lodgingPassengerInformation].includes(
                  parentState
                ) ? (
                  <>
                    <hr />
                    <HotelBookPassengerSelection />
                  </>
                ) : selectedHotelPassenger ? (
                  <Box className="packages-book-filled-section">
                    <Icon name={IconName.User} />
                    <Typography className="packages-book-filled-section-content">
                      {selectedHotelPassenger.givenName}{" "}
                      {selectedHotelPassenger.surname}
                    </Typography>
                  </Box>
                ) : null}
              </Box>
              {![ParentState.lodgingPassengerInformation].includes(
                parentState
              ) &&
                selectedHotelPassenger && (
                  <Box className="packages-book-section-change-cta-wrapper">
                    <ButtonWrap onClick={goToHotelTravelers}>
                      <Typography>{CHANGE_CTA_TEXT}</Typography>
                    </ButtonWrap>
                  </Box>
                )}
            </div>

            <div
              className={clsx("packages-book-section", {
                selected: [ParentState.seatSelection].includes(parentState),
              })}
              ref={seatSelectionSectionRef}
            >
              <Box className="packages-book-section-main-content-wrapper">
                <Typography
                  variant="h3"
                  className="packages-book-section-heading"
                >
                  {SEAT_SELECTION_HEADING}
                </Typography>

                {[ParentState.seatSelection].includes(parentState) ? (
                  <>
                    <hr />
                    <SeatSelection />
                  </>
                ) : selectedSeats.length || skippedSeatSelection ? (
                  <Box className="packages-book-filled-section">
                    {!skippedSeatSelection && <Icon name={IconName.User} />}
                    <Typography className="packages-book-filled-section-content">
                      {skippedSeatSelection
                        ? SKIPPED_SEATS_COMPLETED_TEXT
                        : SELECTED_SEATS_COMPLETED_TEXT(
                            numPassengersWithSelectedSeats,
                            seatTotalPricing
                          )}
                    </Typography>
                  </Box>
                ) : null}
              </Box>
              {![ParentState.seatSelection].includes(parentState) &&
                (selectedSeats.length || skippedSeatSelection) && (
                  <Box className="packages-book-section-change-cta-wrapper">
                    <ButtonWrap onClick={goToSeatSelection}>
                      <Typography>{CHANGE_CTA_TEXT}</Typography>
                    </ButtonWrap>
                  </Box>
                )}
            </div>
          </Box>
          <Box className="packages-book-body-right">
            <div />
          </Box>
        </Box>
      </Box>

      <BookingInProgressModal />
      <BookingErrorModal />
    </Box>
  );
};
