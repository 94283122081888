import { assign } from "xstate";
import { PackagesMachineContext } from "../types";
import { SelectPassengerEvent } from "../events";
import { ParentState } from "@capone/checkout";

export const LodgingPassengerInformationActions = {
  selectPassenger: assign(
    (ctx: PackagesMachineContext, event: SelectPassengerEvent) => {
      const passengerId = event.data?.id || event.passengerId;

      const newPassengerInfoCtx = {
        ...ctx[ParentState.lodgingPassengerInformation],
      };

      newPassengerInfoCtx.selectedPassengerId = passengerId;

      return {
        ...ctx,
        [ParentState.lodgingPassengerInformation]: newPassengerInfoCtx,
      };
    }
  ),
};
