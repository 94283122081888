import {
  IPerson,
  ISelectedTrip,
  PersonId,
  SessionInfo,
  TripDetails,
} from "redmond";
import {
  IContactInfo,
  ParentState,
  getCartInitialContext,
  getAirChfarInitialContext,
  getIntialWalletContext,
} from "@capone/checkout";
import { PackagesMachineContext } from "./types";

export const getInitialPackagesMachineContext =
  function (): PackagesMachineContext {
    return {
      ...getCartInitialContext(),
      sessionInfo: {} as SessionInfo,
      flightSearch: {
        tripCategory: undefined,
        destination: null,
        origin: null,
        departureDate: null,
        returnDate: null,
        originCountryCode: "",
        destinationCountryCode: "",
      },
      flightShop: {
        // TODO: remove when we have real trip details to pull from
        tripDetails: {
          airports: [],
          airlines: [],
          pointOfSaleCountryCode: "US",
          fareDetails: [
            {
              penaltiesInfo: {
                changePenalty: {
                  Penalty: "NotPossible",
                },
                cancelPenalty: {
                  Penalty: "NotPossible",
                },
                PenaltiesInfo: "Known",
              },
              paxPricings: [
                {
                  paxType: "ADT",
                  pricing: {
                    baseAmount: {
                      fiat: {
                        currencyCode: "USD",
                        currencySymbol: "$",
                        value: 229.54,
                      },
                      accountSpecific: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 229.54,
                          currencyDescription: "rewards cash",
                        },
                      },
                      rewards: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 229.54,
                          currencyDescription: "rewards cash",
                        },
                      },
                    },
                    taxAmount: {
                      fiat: {
                        currencyCode: "USD",
                        currencySymbol: "$",
                        value: 47.42,
                      },
                      accountSpecific: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 47.42,
                          currencyDescription: "rewards cash",
                        },
                      },
                      rewards: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 47.42,
                          currencyDescription: "rewards cash",
                        },
                      },
                    },
                    lastTicketDate: "2024-09-28",
                    total: {
                      fiat: {
                        currencyCode: "USD",
                        currencySymbol: "$",
                        value: 276.96,
                      },
                      accountSpecific: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 276.96,
                          currencyDescription: "rewards cash",
                        },
                      },
                      rewards: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 276.96,
                          currencyDescription: "rewards cash",
                        },
                      },
                    },
                  },
                  quantity: 1,
                },
              ],
              timestamp: 1727450330611,
              took: 3186,
              id: "ac435310-8be9-4d8d-953f-4253384fa0b1",
              conversationId: "ef0937e3-c9ec-45c6-80c8-c25e5641f3a7",
              multiTicketType: "single",
              slices: [
                {
                  outgoing: true,
                  sliceIndex: 0,
                  fareShelf: {
                    rating: 0,
                    brandName: "Basic Economy",
                    shortBrandName: "Basic Economy",
                    opaqueMapperStep: "brandName",
                  },
                  fareDetails: {
                    segments: [
                      {
                        penaltiesInfo: {
                          changePenalty: {
                            Penalty: "NotPossible",
                          },
                          cancelPenalty: {
                            Penalty: "NotPossible",
                          },
                          PenaltiesInfo: "Known",
                        },
                        ticketIndex: 0,
                        outgoing: true,
                        brand: {
                          sabreFareBrand: {
                            brandId: "BASICECON",
                            brandName: "BASIC ECONOMY",
                          },
                          amadeusFareBrand: {
                            brandId: "BASICECON",
                            brandName: "BASIC ECONOMY",
                          },
                        },
                        carrierCabinClass: "Y",
                        code: "XAUSA0BC",
                        codes: {
                          ADT: "XAUSA0BC",
                        },
                        validatingCarrierCode: "DL",
                        sliceIndex: 0,
                        bookingCode: "E",
                        privateFare: false,
                        connection: false,
                        isSelfTransferLayover: false,
                        availableSeats: 6,
                        baggageInfo: {
                          allowance: {
                            pieces: 0,
                            descriptions: [],
                          },
                          charges: [
                            {
                              firstPiece: 1,
                              amount: 35,
                              lastPiece: 1,
                              currency: "USD",
                              descriptions: [],
                            },
                            {
                              firstPiece: 2,
                              amount: 45,
                              lastPiece: 2,
                              currency: "USD",
                              descriptions: [],
                            },
                          ],
                          BaggageInfo: "Allowed",
                        },
                        cabinClassName: "Economy",
                      },
                    ],
                  },
                },
                {
                  outgoing: false,
                  sliceIndex: 1,
                  fareShelf: {
                    rating: 0,
                    brandName: "Basic Economy",
                    shortBrandName: "Basic Economy",
                    opaqueMapperStep: "brandName",
                  },
                  fareDetails: {
                    segments: [
                      {
                        penaltiesInfo: {
                          changePenalty: {
                            Penalty: "NotPossible",
                          },
                          cancelPenalty: {
                            Penalty: "NotPossible",
                          },
                          PenaltiesInfo: "Known",
                        },
                        ticketIndex: 0,
                        outgoing: false,
                        brand: {
                          sabreFareBrand: {
                            brandId: "BASICECON",
                            brandName: "BASIC ECONOMY",
                          },
                        },
                        carrierCabinClass: "Y",
                        code: "XAUSA0BC",
                        codes: {
                          ADT: "XAUSA0BC",
                        },
                        validatingCarrierCode: "DL",
                        sliceIndex: 1,
                        bookingCode: "E",
                        privateFare: false,
                        connection: false,
                        isSelfTransferLayover: false,
                        availableSeats: 1,
                        baggageInfo: {
                          allowance: {
                            pieces: 0,
                            descriptions: [],
                          },
                          charges: [
                            {
                              firstPiece: 1,
                              amount: 35,
                              lastPiece: 1,
                              currency: "USD",
                              descriptions: [],
                            },
                            {
                              firstPiece: 2,
                              amount: 45,
                              lastPiece: 2,
                              currency: "USD",
                              descriptions: [],
                            },
                          ],
                          BaggageInfo: "Allowed",
                        },
                        cabinClassName: "Economy",
                      },
                    ],
                  },
                },
              ],
              multiCity: false,
              multiTicket: false,
            },
            {
              penaltiesInfo: {
                changePenalty: {
                  Penalty: "NoPenalty",
                },
                cancelPenalty: {
                  Penalty: "NotPossible",
                },
                PenaltiesInfo: "Known",
              },
              paxPricings: [
                {
                  paxType: "ADT",
                  pricing: {
                    baseAmount: {
                      fiat: {
                        currencyCode: "USD",
                        currencySymbol: "$",
                        value: 322.56,
                      },
                      accountSpecific: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 322.56,
                          currencyDescription: "rewards cash",
                        },
                      },
                      rewards: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 322.56,
                          currencyDescription: "rewards cash",
                        },
                      },
                    },
                    taxAmount: {
                      fiat: {
                        currencyCode: "USD",
                        currencySymbol: "$",
                        value: 51.95,
                      },
                      accountSpecific: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 54.39,
                          currencyDescription: "rewards cash",
                        },
                      },
                      rewards: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 54.39,
                          currencyDescription: "rewards cash",
                        },
                      },
                    },
                    lastTicketDate: "2024-09-28",
                    total: {
                      fiat: {
                        currencyCode: "USD",
                        currencySymbol: "$",
                        value: 341.95,
                      },
                      accountSpecific: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 376.95,
                          currencyDescription: "rewards cash",
                        },
                      },
                      rewards: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 376.95,
                          currencyDescription: "rewards cash",
                        },
                      },
                    },
                  },
                  quantity: 1,
                },
              ],
              timestamp: 1727450330611,
              took: 3186,
              id: "84be6faf-c295-4317-8437-f7c8420f95e0",
              conversationId: "ef0937e3-c9ec-45c6-80c8-c25e5641f3a7",
              multiTicketType: "single",
              slices: [
                {
                  outgoing: true,
                  sliceIndex: 0,
                  fareShelf: {
                    rating: 1,
                    brandName: "Main Cabin",
                    shortBrandName: "Main Cabin",
                    opaqueMapperStep: "brandName",
                  },
                  fareDetails: {
                    segments: [
                      {
                        penaltiesInfo: {
                          changePenalty: {
                            Penalty: "NoPenalty",
                          },
                          cancelPenalty: {
                            Penalty: "NotPossible",
                          },
                          PenaltiesInfo: "Known",
                        },
                        ticketIndex: 0,
                        outgoing: true,
                        brand: {
                          sabreFareBrand: {
                            brandId: "MAINCABIN",
                            brandName: "MAIN CABIN",
                          },
                        },
                        carrierCabinClass: "Y",
                        code: "XAUSA0ME",
                        codes: {
                          ADT: "XAUSA0ME",
                        },
                        validatingCarrierCode: "DL",
                        sliceIndex: 0,
                        bookingCode: "X",
                        privateFare: false,
                        connection: false,
                        isSelfTransferLayover: false,
                        availableSeats: 6,
                        baggageInfo: {
                          allowance: {
                            pieces: 0,
                            descriptions: [],
                          },
                          charges: [
                            {
                              firstPiece: 1,
                              amount: 35,
                              lastPiece: 1,
                              currency: "USD",
                              descriptions: [],
                            },
                            {
                              firstPiece: 2,
                              amount: 45,
                              lastPiece: 2,
                              currency: "USD",
                              descriptions: [],
                            },
                          ],
                          BaggageInfo: "Allowed",
                        },
                        cabinClassName: "Economy",
                      },
                    ],
                  },
                },
                {
                  outgoing: false,
                  sliceIndex: 1,
                  fareShelf: {
                    rating: 1,
                    brandName: "Main Cabin",
                    shortBrandName: "Main Cabin",
                    opaqueMapperStep: "brandName",
                  },
                  fareDetails: {
                    segments: [
                      {
                        penaltiesInfo: {
                          changePenalty: {
                            Penalty: "NoPenalty",
                          },
                          cancelPenalty: {
                            Penalty: "NotPossible",
                          },
                          PenaltiesInfo: "Known",
                        },
                        ticketIndex: 0,
                        outgoing: false,
                        brand: {
                          sabreFareBrand: {
                            brandId: "MAINCABIN",
                            brandName: "MAIN CABIN",
                          },
                        },
                        carrierCabinClass: "Y",
                        code: "XAUSA0ME",
                        codes: {
                          ADT: "XAUSA0ME",
                        },
                        validatingCarrierCode: "DL",
                        sliceIndex: 1,
                        bookingCode: "X",
                        privateFare: false,
                        connection: false,
                        isSelfTransferLayover: false,
                        availableSeats: 1,
                        baggageInfo: {
                          allowance: {
                            pieces: 0,
                            descriptions: [],
                          },
                          charges: [
                            {
                              firstPiece: 1,
                              amount: 35,
                              lastPiece: 1,
                              currency: "USD",
                              descriptions: [],
                            },
                            {
                              firstPiece: 2,
                              amount: 45,
                              lastPiece: 2,
                              currency: "USD",
                              descriptions: [],
                            },
                          ],
                          BaggageInfo: "Allowed",
                        },
                        cabinClassName: "Economy",
                      },
                    ],
                  },
                },
              ],
              multiCity: false,
              multiTicket: false,
            },
            {
              penaltiesInfo: {
                changePenalty: {
                  Penalty: "NoPenalty",
                },
                cancelPenalty: {
                  Penalty: "NotPossible",
                },
                PenaltiesInfo: "Known",
              },
              paxPricings: [
                {
                  paxType: "ADT",
                  pricing: {
                    baseAmount: {
                      fiat: {
                        currencyCode: "USD",
                        currencySymbol: "$",
                        value: 545.82,
                      },
                      accountSpecific: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 545.82,
                          currencyDescription: "rewards cash",
                        },
                      },
                      rewards: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 545.82,
                          currencyDescription: "rewards cash",
                        },
                      },
                    },
                    taxAmount: {
                      fiat: {
                        currencyCode: "USD",
                        currencySymbol: "$",
                        value: 71.14,
                      },
                      accountSpecific: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 71.14,
                          currencyDescription: "rewards cash",
                        },
                      },
                      rewards: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 71.14,
                          currencyDescription: "rewards cash",
                        },
                      },
                    },
                    lastTicketDate: "2024-09-28",
                    total: {
                      fiat: {
                        currencyCode: "USD",
                        currencySymbol: "$",
                        value: 616.96,
                      },
                      accountSpecific: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 616.96,
                          currencyDescription: "rewards cash",
                        },
                      },
                      rewards: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 616.96,
                          currencyDescription: "rewards cash",
                        },
                      },
                    },
                  },
                  quantity: 1,
                },
              ],
              timestamp: 1727450330611,
              took: 3186,
              id: "d76ca2cc-f5bd-4036-aad2-e094bf452ece",
              conversationId: "ef0937e3-c9ec-45c6-80c8-c25e5641f3a7",
              multiTicketType: "single",
              slices: [
                {
                  outgoing: true,
                  sliceIndex: 0,
                  fareShelf: {
                    rating: 2,
                    brandName: "Delta Comfort Plus",
                    shortBrandName: "Delta Comfort Plus",
                    opaqueMapperStep: "brandName",
                  },
                  fareDetails: {
                    segments: [
                      {
                        penaltiesInfo: {
                          changePenalty: {
                            Penalty: "NoPenalty",
                          },
                          cancelPenalty: {
                            Penalty: "NotPossible",
                          },
                          PenaltiesInfo: "Known",
                        },
                        ticketIndex: 0,
                        outgoing: true,
                        brand: {
                          sabreFareBrand: {
                            brandId: "COMFORTPLU",
                            brandName: "DELTA COMFORT PLUS",
                          },
                        },
                        carrierCabinClass: "W",
                        code: "XAUSA0IE",
                        codes: {
                          ADT: "XAUSA0IE",
                        },
                        validatingCarrierCode: "DL",
                        sliceIndex: 0,
                        bookingCode: "S",
                        privateFare: false,
                        connection: false,
                        isSelfTransferLayover: false,
                        availableSeats: 6,
                        baggageInfo: {
                          allowance: {
                            pieces: 0,
                            descriptions: [],
                          },
                          charges: [
                            {
                              firstPiece: 1,
                              amount: 35,
                              lastPiece: 1,
                              currency: "USD",
                              descriptions: [],
                            },
                            {
                              firstPiece: 2,
                              amount: 45,
                              lastPiece: 2,
                              currency: "USD",
                              descriptions: [],
                            },
                          ],
                          BaggageInfo: "Allowed",
                        },
                        cabinClassName: "Premium Economy",
                      },
                    ],
                  },
                },
                {
                  outgoing: false,
                  sliceIndex: 1,
                  fareShelf: {
                    rating: 2,
                    brandName: "Delta Comfort Plus",
                    shortBrandName: "Delta Comfort Plus",
                    opaqueMapperStep: "brandName",
                  },
                  fareDetails: {
                    segments: [
                      {
                        penaltiesInfo: {
                          changePenalty: {
                            Penalty: "NoPenalty",
                          },
                          cancelPenalty: {
                            Penalty: "NotPossible",
                          },
                          PenaltiesInfo: "Known",
                        },
                        ticketIndex: 0,
                        outgoing: false,
                        brand: {
                          sabreFareBrand: {
                            brandId: "COMFORTPLU",
                            brandName: "DELTA COMFORT PLUS",
                          },
                        },
                        carrierCabinClass: "W",
                        code: "XAUSA0IE",
                        codes: {
                          ADT: "XAUSA0IE",
                        },
                        validatingCarrierCode: "DL",
                        sliceIndex: 1,
                        bookingCode: "S",
                        privateFare: false,
                        connection: false,
                        isSelfTransferLayover: false,
                        availableSeats: 1,
                        baggageInfo: {
                          allowance: {
                            pieces: 0,
                            descriptions: [],
                          },
                          charges: [
                            {
                              firstPiece: 1,
                              amount: 35,
                              lastPiece: 1,
                              currency: "USD",
                              descriptions: [],
                            },
                            {
                              firstPiece: 2,
                              amount: 45,
                              lastPiece: 2,
                              currency: "USD",
                              descriptions: [],
                            },
                          ],
                          BaggageInfo: "Allowed",
                        },
                        cabinClassName: "Premium Economy",
                      },
                    ],
                  },
                },
              ],
              multiCity: false,
              multiTicket: false,
            },
            {
              penaltiesInfo: {
                changePenalty: {
                  Penalty: "NoPenalty",
                },
                cancelPenalty: {
                  Penalty: "NoPenalty",
                },
                PenaltiesInfo: "Known",
              },
              paxPricings: [
                {
                  paxType: "ADT",
                  pricing: {
                    baseAmount: {
                      fiat: {
                        currencyCode: "USD",
                        currencySymbol: "$",
                        value: 564.42,
                      },
                      accountSpecific: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 564.42,
                          currencyDescription: "rewards cash",
                        },
                      },
                      rewards: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 564.42,
                          currencyDescription: "rewards cash",
                        },
                      },
                    },
                    taxAmount: {
                      fiat: {
                        currencyCode: "USD",
                        currencySymbol: "$",
                        value: 72.53,
                      },
                      accountSpecific: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 72.53,
                          currencyDescription: "rewards cash",
                        },
                      },
                      rewards: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 72.53,
                          currencyDescription: "rewards cash",
                        },
                      },
                    },
                    lastTicketDate: "2024-09-28",
                    total: {
                      fiat: {
                        currencyCode: "USD",
                        currencySymbol: "$",
                        value: 636.95,
                      },
                      accountSpecific: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 636.95,
                          currencyDescription: "rewards cash",
                        },
                      },
                      rewards: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 636.95,
                          currencyDescription: "rewards cash",
                        },
                      },
                    },
                  },
                  quantity: 1,
                },
              ],
              timestamp: 1727450330611,
              took: 3186,
              id: "8416b5a8-cb62-440d-a58d-b0f435012c3d",
              conversationId: "ef0937e3-c9ec-45c6-80c8-c25e5641f3a7",
              multiTicketType: "single",
              slices: [
                {
                  outgoing: true,
                  sliceIndex: 0,
                  fareShelf: {
                    rating: 1,
                    brandName: "Refundable Main Cabin",
                    shortBrandName: "Refundable Main Cabin",
                    opaqueMapperStep: "ua_dl_ref-brandName",
                  },
                  fareDetails: {
                    segments: [
                      {
                        penaltiesInfo: {
                          changePenalty: {
                            Penalty: "NoPenalty",
                          },
                          cancelPenalty: {
                            Penalty: "NoPenalty",
                          },
                          PenaltiesInfo: "Known",
                        },
                        ticketIndex: 0,
                        outgoing: true,
                        brand: {
                          sabreFareBrand: {
                            brandId: "REFMAIN",
                            brandName: "REFUNDABLE MAIN CABIN",
                          },
                        },
                        carrierCabinClass: "Y",
                        code: "UAURA0M6",
                        codes: {
                          ADT: "UAURA0M6",
                        },
                        validatingCarrierCode: "DL",
                        sliceIndex: 0,
                        bookingCode: "U",
                        privateFare: false,
                        connection: false,
                        isSelfTransferLayover: false,
                        availableSeats: 9,
                        baggageInfo: {
                          allowance: {
                            pieces: 0,
                            descriptions: [],
                          },
                          charges: [
                            {
                              firstPiece: 1,
                              amount: 35,
                              lastPiece: 1,
                              currency: "USD",
                              descriptions: [],
                            },
                            {
                              firstPiece: 2,
                              amount: 45,
                              lastPiece: 2,
                              currency: "USD",
                              descriptions: [],
                            },
                          ],
                          BaggageInfo: "Allowed",
                        },
                        cabinClassName: "Economy",
                      },
                    ],
                  },
                },
                {
                  outgoing: false,
                  sliceIndex: 1,
                  fareShelf: {
                    rating: 1,
                    brandName: "Refundable Main Cabin",
                    shortBrandName: "Refundable Main Cabin",
                    opaqueMapperStep: "ua_dl_ref-brandName",
                  },
                  fareDetails: {
                    segments: [
                      {
                        penaltiesInfo: {
                          changePenalty: {
                            Penalty: "NoPenalty",
                          },
                          cancelPenalty: {
                            Penalty: "NoPenalty",
                          },
                          PenaltiesInfo: "Known",
                        },
                        ticketIndex: 0,
                        outgoing: false,
                        brand: {
                          sabreFareBrand: {
                            brandId: "REFMAIN",
                            brandName: "REFUNDABLE MAIN CABIN",
                          },
                        },
                        carrierCabinClass: "Y",
                        code: "UAURA0M6",
                        codes: {
                          ADT: "UAURA0M6",
                        },
                        validatingCarrierCode: "DL",
                        sliceIndex: 1,
                        bookingCode: "U",
                        privateFare: false,
                        connection: false,
                        isSelfTransferLayover: false,
                        availableSeats: 9,
                        baggageInfo: {
                          allowance: {
                            pieces: 0,
                            descriptions: [],
                          },
                          charges: [
                            {
                              firstPiece: 1,
                              amount: 35,
                              lastPiece: 1,
                              currency: "USD",
                              descriptions: [],
                            },
                            {
                              firstPiece: 2,
                              amount: 45,
                              lastPiece: 2,
                              currency: "USD",
                              descriptions: [],
                            },
                          ],
                          BaggageInfo: "Allowed",
                        },
                        cabinClassName: "Economy",
                      },
                    ],
                  },
                },
              ],
              multiCity: false,
              multiTicket: false,
            },
            {
              penaltiesInfo: {
                changePenalty: {
                  Penalty: "NoPenalty",
                },
                cancelPenalty: {
                  Penalty: "NoPenalty",
                },
                PenaltiesInfo: "Known",
              },
              paxPricings: [
                {
                  paxType: "ADT",
                  pricing: {
                    baseAmount: {
                      fiat: {
                        currencyCode: "USD",
                        currencySymbol: "$",
                        value: 769.08,
                      },
                      accountSpecific: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 769.08,
                          currencyDescription: "rewards cash",
                        },
                      },
                      rewards: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 769.08,
                          currencyDescription: "rewards cash",
                        },
                      },
                    },
                    taxAmount: {
                      fiat: {
                        currencyCode: "USD",
                        currencySymbol: "$",
                        value: 87.88,
                      },
                      accountSpecific: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 87.88,
                          currencyDescription: "rewards cash",
                        },
                      },
                      rewards: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 87.88,
                          currencyDescription: "rewards cash",
                        },
                      },
                    },
                    lastTicketDate: "2024-09-28",
                    total: {
                      fiat: {
                        currencyCode: "USD",
                        currencySymbol: "$",
                        value: 856.96,
                      },
                      accountSpecific: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 856.96,
                          currencyDescription: "rewards cash",
                        },
                      },
                      rewards: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 856.96,
                          currencyDescription: "rewards cash",
                        },
                      },
                    },
                  },
                  quantity: 1,
                },
              ],
              timestamp: 1727450330611,
              took: 3186,
              id: "dd9f3de7-6655-482d-94cd-c2ea1be980ed",
              conversationId: "ef0937e3-c9ec-45c6-80c8-c25e5641f3a7",
              multiTicketType: "single",
              slices: [
                {
                  outgoing: true,
                  sliceIndex: 0,
                  fareShelf: {
                    rating: 2,
                    brandName: "Refundable Delta Comfort Plus",
                    shortBrandName: "Refundable Delta Comfort Plus",
                    opaqueMapperStep: "sabreBrandId",
                  },
                  fareDetails: {
                    segments: [
                      {
                        penaltiesInfo: {
                          changePenalty: {
                            Penalty: "NoPenalty",
                          },
                          cancelPenalty: {
                            Penalty: "NoPenalty",
                          },
                          PenaltiesInfo: "Known",
                        },
                        ticketIndex: 0,
                        outgoing: true,
                        brand: {
                          sabreFareBrand: {
                            brandId: "REFCPLUS",
                            brandName: "REFUNDABLE DELTA COMFORT PLUS",
                          },
                        },
                        carrierCabinClass: "W",
                        code: "TAVRA0IL",
                        codes: {
                          ADT: "TAVRA0IL",
                        },
                        validatingCarrierCode: "DL",
                        sliceIndex: 0,
                        bookingCode: "S",
                        privateFare: false,
                        connection: false,
                        isSelfTransferLayover: false,
                        availableSeats: 9,
                        baggageInfo: {
                          allowance: {
                            pieces: 0,
                            descriptions: [],
                          },
                          charges: [
                            {
                              firstPiece: 1,
                              amount: 35,
                              lastPiece: 1,
                              currency: "USD",
                              descriptions: [],
                            },
                            {
                              firstPiece: 2,
                              amount: 45,
                              lastPiece: 2,
                              currency: "USD",
                              descriptions: [],
                            },
                          ],
                          BaggageInfo: "Allowed",
                        },
                        cabinClassName: "Premium Economy",
                      },
                    ],
                  },
                },
                {
                  outgoing: false,
                  sliceIndex: 1,
                  fareShelf: {
                    rating: 2,
                    brandName: "Refundable Delta Comfort Plus",
                    shortBrandName: "Refundable Delta Comfort Plus",
                    opaqueMapperStep: "sabreBrandId",
                  },
                  fareDetails: {
                    segments: [
                      {
                        penaltiesInfo: {
                          changePenalty: {
                            Penalty: "NoPenalty",
                          },
                          cancelPenalty: {
                            Penalty: "NoPenalty",
                          },
                          PenaltiesInfo: "Known",
                        },
                        ticketIndex: 0,
                        outgoing: false,
                        brand: {
                          sabreFareBrand: {
                            brandId: "REFCPLUS",
                            brandName: "REFUNDABLE DELTA COMFORT PLUS",
                          },
                        },
                        carrierCabinClass: "W",
                        code: "TAVRA0IL",
                        codes: {
                          ADT: "TAVRA0IL",
                        },
                        validatingCarrierCode: "DL",
                        sliceIndex: 1,
                        bookingCode: "S",
                        privateFare: false,
                        connection: false,
                        isSelfTransferLayover: false,
                        availableSeats: 9,
                        baggageInfo: {
                          allowance: {
                            pieces: 0,
                            descriptions: [],
                          },
                          charges: [
                            {
                              firstPiece: 1,
                              amount: 35,
                              lastPiece: 1,
                              currency: "USD",
                              descriptions: [],
                            },
                            {
                              firstPiece: 2,
                              amount: 45,
                              lastPiece: 2,
                              currency: "USD",
                              descriptions: [],
                            },
                          ],
                          BaggageInfo: "Allowed",
                        },
                        cabinClassName: "Premium Economy",
                      },
                    ],
                  },
                },
              ],
              multiCity: false,
              multiTicket: false,
            },
            {
              penaltiesInfo: {
                changePenalty: {
                  Penalty: "NoPenalty",
                },
                cancelPenalty: {
                  Penalty: "NotPossible",
                },
                PenaltiesInfo: "Known",
              },
              paxPricings: [
                {
                  paxType: "ADT",
                  pricing: {
                    baseAmount: {
                      fiat: {
                        currencyCode: "USD",
                        currencySymbol: "$",
                        value: 2435.12,
                      },
                      accountSpecific: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 2435.12,
                          currencyDescription: "rewards cash",
                        },
                      },
                      rewards: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 2435.12,
                          currencyDescription: "rewards cash",
                        },
                      },
                    },
                    taxAmount: {
                      fiat: {
                        currencyCode: "USD",
                        currencySymbol: "$",
                        value: 212.83,
                      },
                      accountSpecific: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 212.83,
                          currencyDescription: "rewards cash",
                        },
                      },
                      rewards: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 212.83,
                          currencyDescription: "rewards cash",
                        },
                      },
                    },
                    lastTicketDate: "2024-09-28",
                    total: {
                      fiat: {
                        currencyCode: "USD",
                        currencySymbol: "$",
                        value: 2647.95,
                      },
                      accountSpecific: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 2647.95,
                          currencyDescription: "rewards cash",
                        },
                      },
                      rewards: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 2647.95,
                          currencyDescription: "rewards cash",
                        },
                      },
                    },
                  },
                  quantity: 1,
                },
              ],
              timestamp: 1727450330611,
              took: 3186,
              id: "b70aae30-a284-486d-9616-dd667879a3cf",
              conversationId: "ef0937e3-c9ec-45c6-80c8-c25e5641f3a7",
              multiTicketType: "single",
              slices: [
                {
                  outgoing: true,
                  sliceIndex: 0,
                  fareShelf: {
                    rating: 4,
                    brandName: "Delta One",
                    shortBrandName: "Delta One",
                    opaqueMapperStep: "brandName",
                  },
                  fareDetails: {
                    segments: [
                      {
                        penaltiesInfo: {
                          changePenalty: {
                            Penalty: "NoPenalty",
                          },
                          cancelPenalty: {
                            Penalty: "NotPossible",
                          },
                          PenaltiesInfo: "Known",
                        },
                        ticketIndex: 0,
                        outgoing: true,
                        brand: {
                          sabreFareBrand: {
                            brandId: "DELTAONE",
                            brandName: "DELTA ONE",
                          },
                        },
                        carrierCabinClass: "C",
                        code: "ZFVKA0DQ",
                        codes: {
                          ADT: "ZFVKA0DQ",
                        },
                        validatingCarrierCode: "DL",
                        sliceIndex: 0,
                        bookingCode: "Z",
                        privateFare: false,
                        connection: false,
                        isSelfTransferLayover: false,
                        availableSeats: 3,
                        baggageInfo: {
                          allowance: {
                            pieces: 2,
                            descriptions: [],
                          },
                          charges: [],
                          BaggageInfo: "Allowed",
                        },
                        cabinClassName: "Business",
                      },
                    ],
                  },
                },
                {
                  outgoing: false,
                  sliceIndex: 1,
                  fareShelf: {
                    rating: 4,
                    brandName: "Delta One",
                    shortBrandName: "Delta One",
                    opaqueMapperStep: "brandName",
                  },
                  fareDetails: {
                    segments: [
                      {
                        penaltiesInfo: {
                          changePenalty: {
                            Penalty: "NoPenalty",
                          },
                          cancelPenalty: {
                            Penalty: "NotPossible",
                          },
                          PenaltiesInfo: "Known",
                        },
                        ticketIndex: 0,
                        outgoing: false,
                        brand: {
                          sabreFareBrand: {
                            brandId: "DELTAONE",
                            brandName: "DELTA ONE",
                          },
                        },
                        carrierCabinClass: "C",
                        code: "IAVKA0DQ",
                        codes: {
                          ADT: "IAVKA0DQ",
                        },
                        validatingCarrierCode: "DL",
                        sliceIndex: 1,
                        bookingCode: "I",
                        privateFare: false,
                        connection: false,
                        isSelfTransferLayover: false,
                        availableSeats: 9,
                        baggageInfo: {
                          allowance: {
                            pieces: 2,
                            descriptions: [],
                          },
                          charges: [],
                          BaggageInfo: "Allowed",
                        },
                        cabinClassName: "Business",
                      },
                    ],
                  },
                },
              ],
              multiCity: false,
              multiTicket: false,
            },
            {
              penaltiesInfo: {
                changePenalty: {
                  Penalty: "NoPenalty",
                },
                cancelPenalty: {
                  Penalty: "NoPenalty",
                },
                PenaltiesInfo: "Known",
              },
              paxPricings: [
                {
                  paxType: "ADT",
                  pricing: {
                    baseAmount: {
                      fiat: {
                        currencyCode: "USD",
                        currencySymbol: "$",
                        value: 3179.31,
                      },
                      accountSpecific: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 3179.31,
                          currencyDescription: "rewards cash",
                        },
                      },
                      rewards: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 3179.31,
                          currencyDescription: "rewards cash",
                        },
                      },
                    },
                    taxAmount: {
                      fiat: {
                        currencyCode: "USD",
                        currencySymbol: "$",
                        value: 268.65,
                      },
                      accountSpecific: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 268.65,
                          currencyDescription: "rewards cash",
                        },
                      },
                      rewards: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 268.65,
                          currencyDescription: "rewards cash",
                        },
                      },
                    },
                    lastTicketDate: "2024-09-28",
                    total: {
                      fiat: {
                        currencyCode: "USD",
                        currencySymbol: "$",
                        value: 3447.96,
                      },
                      accountSpecific: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 3447.96,
                          currencyDescription: "rewards cash",
                        },
                      },
                      rewards: {
                        "t.063d0b72688d4b139be8caa099cd0345": {
                          currency: "Cash",
                          value: 3447.96,
                          currencyDescription: "rewards cash",
                        },
                      },
                    },
                  },
                  quantity: 1,
                },
              ],
              timestamp: 1727450330611,
              took: 3186,
              id: "73599e70-fb4b-4c3b-9e35-d6ce201b7738",
              conversationId: "ef0937e3-c9ec-45c6-80c8-c25e5641f3a7",
              multiTicketType: "single",
              slices: [
                {
                  outgoing: true,
                  sliceIndex: 0,
                  fareShelf: {
                    rating: 4,
                    brandName: "Refundable Delta One",
                    shortBrandName: "Refundable Delta One",
                    opaqueMapperStep: "bookingCodeFareBasis",
                  },
                  fareDetails: {
                    segments: [
                      {
                        penaltiesInfo: {
                          changePenalty: {
                            Penalty: "NoPenalty",
                          },
                          cancelPenalty: {
                            Penalty: "NoPenalty",
                          },
                          PenaltiesInfo: "Known",
                        },
                        ticketIndex: 0,
                        outgoing: true,
                        brand: {
                          sabreFareBrand: {
                            brandId: "REFDLONE",
                            brandName: "REFUNDABLE DELTA ONE",
                          },
                        },
                        carrierCabinClass: "C",
                        code: "ZFVHA0DQ",
                        codes: {
                          ADT: "ZFVHA0DQ",
                        },
                        validatingCarrierCode: "DL",
                        sliceIndex: 0,
                        bookingCode: "Z",
                        privateFare: false,
                        connection: false,
                        isSelfTransferLayover: false,
                        availableSeats: 3,
                        baggageInfo: {
                          allowance: {
                            pieces: 2,
                            descriptions: [],
                          },
                          charges: [],
                          BaggageInfo: "Allowed",
                        },
                        cabinClassName: "Business",
                      },
                    ],
                  },
                },
                {
                  outgoing: false,
                  sliceIndex: 1,
                  fareShelf: {
                    rating: 4,
                    brandName: "Refundable Delta One",
                    shortBrandName: "Refundable Delta One",
                    opaqueMapperStep: "bookingCodeFareBasis",
                  },
                  fareDetails: {
                    segments: [
                      {
                        penaltiesInfo: {
                          changePenalty: {
                            Penalty: "NoPenalty",
                          },
                          cancelPenalty: {
                            Penalty: "NoPenalty",
                          },
                          PenaltiesInfo: "Known",
                        },
                        ticketIndex: 0,
                        outgoing: false,
                        brand: {
                          sabreFareBrand: {
                            brandId: "REFDLONE",
                            brandName: "REFUNDABLE DELTA ONE",
                          },
                        },
                        carrierCabinClass: "C",
                        code: "IAVHA0DQ",
                        codes: {
                          ADT: "IAVHA0DQ",
                        },
                        validatingCarrierCode: "DL",
                        sliceIndex: 1,
                        bookingCode: "I",
                        privateFare: false,
                        connection: false,
                        isSelfTransferLayover: false,
                        availableSeats: 9,
                        baggageInfo: {
                          allowance: {
                            pieces: 2,
                            descriptions: [],
                          },
                          charges: [],
                          BaggageInfo: "Allowed",
                        },
                        cabinClassName: "Business",
                      },
                    ],
                  },
                },
              ],
              multiCity: false,
              multiTicket: false,
            },
          ],
          id: "789f627a-852b-4fdd-a227-0c30df041542/AIHz87CkXr0BbufMOpRDwEk5BHno6B",
          validatingCarrierCode: "DL",
          tripDescription:
            "Flight DL701/DL960 JFK<->LAX 2024-10-25 to 2024-10-27",
          slices: [
            {
              departureTime: "2024-10-25T06:00:00.000-04:00",
              zonedDepartureTime: "2024-10-25T06:00-04:00",
              outgoing: true,
              arrivalTime: "2024-10-25T09:05:00.000-07:00",
              destinationName: "Los Angeles International Airport",
              originCode: "JFK",
              id: "7a4df3e0-1db7-3ac8-a278-a7f5ccfbc1bf",
              segmentDetails: [
                {
                  departureTime: "2024-10-25T06:00:00.000-04:00",
                  airlineName: "Delta",
                  airlineCode: "DL",
                  zonedDepartureTime: "2024-10-25T06:00-04:00",
                  zonedArrivalTime: "2024-10-25T09:05-07:00",
                  plusDays: 0,
                  marketingAirline: {
                    code: "DL",
                    name: "Delta",
                  },
                  arrivalTime: "2024-10-25T09:05:00.000-07:00",
                  destinationName: "Los Angeles International Airport",
                  originCode: "JFK",
                  originName: "John F. Kennedy International Airport",
                  operatingAirline: {
                    code: "DL",
                    name: "Delta",
                  },
                  flightNumber: "701",
                  destinationCode: "LAX",
                  stops: 0,
                },
              ],
              originName: "John F. Kennedy International Airport",
              totalDurationMinutes: 365,
              destinationCode: "LAX",
              stops: 0,
            },
            {
              departureTime: "2024-10-26T21:30:00.000-07:00",
              zonedDepartureTime: "2024-10-26T21:30-07:00",
              outgoing: false,
              arrivalTime: "2024-10-27T05:50:00.000-04:00",
              destinationName: "John F. Kennedy International Airport",
              originCode: "LAX",
              id: "9728a63f-837b-3ea9-a16d-e9d2c40fc0a0",
              segmentDetails: [
                {
                  departureTime: "2024-10-26T21:30:00.000-07:00",
                  airlineName: "Delta",
                  airlineCode: "DL",
                  zonedDepartureTime: "2024-10-26T21:30-07:00",
                  zonedArrivalTime: "2024-10-27T05:50-04:00",
                  plusDays: 1,
                  marketingAirline: {
                    code: "DL",
                    name: "Delta",
                  },
                  arrivalTime: "2024-10-27T05:50:00.000-04:00",
                  destinationName: "John F. Kennedy International Airport",
                  originCode: "LAX",
                  originName: "Los Angeles International Airport",
                  operatingAirline: {
                    code: "DL",
                    name: "Delta",
                  },
                  flightNumber: "960",
                  destinationCode: "JFK",
                  stops: 0,
                },
              ],
              originName: "Los Angeles International Airport",
              totalDurationMinutes: 320,
              destinationCode: "JFK",
              stops: 0,
            },
          ],
        } as unknown as TripDetails,
        selectedTrip: {
          tripId:
            "789f627a-852b-4fdd-a227-0c30df041542/AIHz87CkXr0BbufMOpRDwEk5BHno6B",
          outgoingSliceId: "34213f0e-0b9e-31f6-a719-c47048588fc5",
          outgoingFareId: "84be6faf-c295-4317-8437-f7c8420f95e0",
          outgoingFareRating: 1,
          returnSliceId: null,
          returnFareId: null,
          returnFareRating: 1,
        } as ISelectedTrip,
        shopPricingInfo: {
          fare: undefined,
        },
        departureLabel: "",
        returnLabel: undefined,
        airports: undefined,
      },
      lodgingShop: {
        fromDate: undefined,
        untilDate: undefined,
        selectedLodging: undefined,
        selectedRoom: undefined,
        selectedRoomRateId: undefined,
      },
      common: {
        tripPricing: undefined,
      },
      [ParentState.cancelForAnyReason]: {
        cfarQuoteId: undefined,
        cfarOffers: undefined,
      },
      [ParentState.changeForAnyReason]: { ...getAirChfarInitialContext() },
      [ParentState.passengerInformation]: {
        selectedPassengerIds: [] as Array<PersonId>,
        selectedLapInfantIds: [] as Array<PersonId>,
        userPassengers: [] as Array<IPerson>,
        addPassport: true,
        infantOnlyError: false,
        visited: false,
      },
      [ParentState.lodgingPassengerInformation]: {
        selectedPassengerId: undefined,
      },
      [ParentState.contactInformation]: {
        contactInfo: {} as IContactInfo,
        visited: false,
      },
      [ParentState.passport]: {
        error: undefined,
      },
      [ParentState.seatSelection]: {
        selectedSeats: [],
        selectedSeatSegments: [],
        cheapestSeat: undefined,
        seatMapHtml: undefined,
        seatMapRequest: undefined,
        seatsInfo: undefined,
        seatMapAvailable: undefined,
        visited: false,
        skipSeatSelection: false,
      },
      [ParentState.review]: {
        visited: false,
      },
      // [ParentState.awardsPayment]: {
      //   selectedAccount: null,
      //   awardsAccounts: [],
      //   awardsAmountToApply: 0,
      //   awardsCashAmountToApply: 0,
      //   visited: false,
      //   error: false,
      // },
      [ParentState.cardPayment]: {
        paymentMethods: [],
        selectedPaymentMethodId: undefined,
        spreedlyToken: undefined,
        error: undefined,
        visited: false,
      },
      [ParentState.bookingConfirmation]: {},
      [ParentState.disruption]: {
        selectedOffers: [],
        offersResponse: [],
      },
      [ParentState.priceDrop]: {
        candidateId: undefined,
      },
      [ParentState.changeForAnyReasonDiscount]: {},
      [ParentState.wallet]: { ...getIntialWalletContext() },
      // walletPayment: {
      //   creditPaymentAmount: {
      //     value: 0,
      //     currencyCode: undefined,
      //     currencySymbol: undefined,
      //   },
      //   creditBalance: {
      //     value: 0,
      //     currencyCode: undefined,
      //     currencySymbol: undefined,
      //   },
      // },
    };
  };
